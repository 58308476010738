import { Component, Input } from "@angular/core";

@Component({
	selector: 'app-auth-base',
	templateUrl: './base.component.html',
})

export class BaseAuthComponent {
    @Input() generalTitle: string = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci architecto asperiores consequuntur, culpa, ducimus fugit minima modi natus necessitatibus numquam perspiciatis placeat quasi quia reiciendis rerum tempora tempore totam.';
	@Input() formTitle: string;
}