import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: 'app-auth-button',
	templateUrl: './button.component.html',
})

export class ButtonAuthComponent {

    @Input() title: string;
    @Input() disabled: boolean = true;
    @Output() submit = new EventEmitter<void>();

    public onSubmit()
    {
        this.submit.emit();
    }

}