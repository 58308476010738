<app-auth-base formTitle="{{'auth.forgot.title' | translate}}">
    <form class="mt-8 space-y-6" [formGroup]="forgotForm">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px" *ngIf="!success">
            <div>
                <label for="email-address" class="sr-only">{{'auth.email' | translate}}</label>
                <input formControlName="email" type="email" autocomplete="email" required
                       class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-bizdata-800 focus:border-bizdata-800 focus:z-10 sm:text-sm"
                       placeholder="{{'auth.email' | translate}}">
                <ng-container *ngIf="f.email.errors">
                    <app-error-form *ngIf="f.email.errors.email" error="{{'errors.email' |
                        translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>

        <div class="mb-6" *ngIf="errorMessage">
            <app-error-form error="{{errorMessage}}">
            </app-error-form>
        </div>

        <div class="mb-6" *ngIf="success">
            <p class="text-green-500 text-xs italic">
                {{'auth.forgot.success' | translate}}
            </p>
        </div>

        <div *ngIf="!success">
            <app-auth-button title="{{'auth.forgot.button' | translate}}" [disabled]="disabledButton" (submit)="onSubmit()"></app-auth-button>
        </div>
    </form>
</app-auth-base>