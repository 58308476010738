import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';

import { DashboardRoutingModule } from './dashboard-routing.module';

import { TranslateModule } from '@ngx-translate/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/modules/shared.module';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './home/menu/menu.component';
import { ModalDataComponent } from '../shared/components/modals/data/modal-data.component';
import { FloorModalComponent } from './home/menu/floor/create/floor-crete.component';
import { FloorModalHwComponent } from './home/menu/floor/hw/floor-hw.component';
import { ConditionModalComponent } from './home/menu/condition/condition.component';
import { ModalInfoOptionsComponent } from '../shared/components/modals/info-options/modal-info-options.component';

registerLocaleData(localeEs);


@NgModule({
    declarations: [
        HomeComponent,
        MenuComponent,
        ModalDataComponent,
        ModalInfoOptionsComponent,
        FloorModalComponent,
        FloorModalHwComponent,
        ConditionModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DashboardRoutingModule,
        RouterModule,
        TranslateModule.forChild()
    ],
    exports: [
        SharedModule,
        MenuComponent,
    ],
    providers: [],
})

export class DashboardModule {

}