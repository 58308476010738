import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
	{ path: '', redirectTo: '', pathMatch: 'full' },
	{ 
		path: '',
		loadChildren: () => import('./dashboard/dashboard.module').then(x => x.DashboardModule),
		canActivate: [AuthGuard] 
	},
  	{ path: 'login', component: LoginComponent },
	{ path: '**', component: NopagefoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
		AuthRoutingModule,
	],
	exports: [RouterModule, DashboardModule]
})
export class AppRoutingModule {

}
