import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/modules/shared.module';

import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { ForgotPasswordComponent } from './forgot/forgot.component';
import { ResetPasswordComponent } from './reset/reset.component';
import { ButtonAuthComponent } from './shared/buton.component';
import { BaseAuthComponent } from './shared/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoginGuard } from './login.guard';

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        BaseAuthComponent,
        ButtonAuthComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AuthRoutingModule,
        TranslateModule.forChild()
    ],
    exports: [
        SharedModule
    ],
    providers: [AuthService, LoginGuard],
})

export class AuthModule { }