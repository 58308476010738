import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs/internal/Subject";


@Injectable({
    providedIn: 'root'
})
export class MapLeafletService {
    
    private defaultControls = {  
        position: 'topleft',  
        drawMarker: false,  
        drawCircle: false,  
        drawCircleMarker: false,  
        cutPolygon: false,
        editMode: false,
        dragMode: false,
        removalMode: false,
        drawPolyline: false,  
        drawRectangle: false,  
        drawPolygon: false
    };
    

    // controls
    private addControl = new BehaviorSubject<any>(this.defaultControls);
    public addControl$ = this.addControl.asObservable();

    addControlsToMap(controls) {
        this.addControl.next(controls); 
    }
 
    setDefaultControls() {
        this.addControlsToMap(this.defaultControls);
    }
 
    getControls() {
        return this.addControl.getValue();
    }

    // step
    private step = new BehaviorSubject<number>(1);
    public step$ = this.step.asObservable();

    getStep(): number {
        return this.step.getValue();
    }

    setStep(step: number): void {
        this.step.next(step);
    }

    // line
    private lineFeature = new Subject<any>();
    public lineFeature$ = this.lineFeature.asObservable();

    changeLine(feature) {
        this.lineFeature.next(feature);
    }

    removeLine() {
        this.lineFeature.next();
    }

    // perimeter
    private perimeterFeature = new Subject<any>();
    public perimeterFeature$ = this.perimeterFeature.asObservable();

    changePerimeter(feature) {
        this.perimeterFeature.next(feature);
    }

    removePerimeter() {
        this.perimeterFeature.next();
    }

    // zone
    private zoneFeature = new Subject<any>();
    public zoneFeature$ = this.zoneFeature.asObservable();

    changeZone(feature) {
        this.zoneFeature.next(feature);
    }

    removeZone() {
        this.zoneFeature.next();
    }

    // clear
    private mapClear = new Subject<void>();
    public mapClear$ = this.mapClear.asObservable();

    clearMap() {
        this.mapClear.next();
    }

    // add feature
    private addFeature = new Subject<any>();
    public addFeature$ = this.addFeature.asObservable();

    addFeatureToMap(feature) {
        this.addFeature.next(feature);
    }

    // set type marker
    private typeMarker = new Subject<any>();
    public typeMarker$ = this.typeMarker.asObservable();

    setTypeMarker(typeMarker: string, id: number) {
        this.typeMarker.next({type: typeMarker, id: id});
    }

    // set type marker
    private currentZone = new Subject<string>();
    public currentZone$ = this.currentZone.asObservable();

    setCurrentZone(currentZone: string) {
        this.currentZone.next(currentZone);
    }

    // add marker
    private markerFeature = new Subject<any>();
    public markerFeature$ = this.markerFeature.asObservable();

    addMarker(feature) {
        this.markerFeature.next(feature);
    }

    removeMarker(feature) {
        this.markerFeature.next(feature.properties);
    }

    // add cirucle
    private circleFeature = new Subject<any>();
    public circleFeature$ = this.circleFeature.asObservable();
 
    addCircle(circle) {
        this.circleFeature.next(circle);
    }

    // add circle
    private updateLayer = new Subject<any>();
    public updateLayer$ = this.updateLayer.asObservable();
 
    updateTileLayer(url) {
        this.updateLayer.next(url);
    }

    // add points
    private pointsFeature = new Subject<any>();
    public pointsFeature$ = this.pointsFeature.asObservable();

    addPoints(points) {
        this.pointsFeature.next(points);
    }
}