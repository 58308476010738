import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Output } from "@angular/core";
import { OptionModal } from "src/app/shared/interfaces/optionModal.interface";
import { Categories } from "./categories";
import { ConditionToSend } from "./interfaces/conditionSend.interface";
import { DataCondition } from "./interfaces/dataCondition.interface";

@Component({
    selector: 'app-condition-modal',
    templateUrl: './condition.component.html',
    animations: [
        trigger(
            'moveBetweenSteps', [
                transition(':enter', [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
                ])
            ]
        )
    ]
})

export class ConditionModalComponent {
    
    @Output() closeModal;
    @Output() buttonAction;
    
    step: number = 1;

    options: OptionModal[] = [
        {
            optionReturn: 'nextStep',
            class: 'button-ok-form',
            text: 'Siguiente'
        },
        {
            optionReturn: 'previousStep',
            class: 'button-ok-form',
            text: 'Atras'
        },
        {
            optionReturn: 'closeModalF',
            class: 'button-nok-form',
            text: 'Cancelar'
        }
    ];

    simpleInequalityTypes = [
        {
            value: 'Igual al',
            text: 'Igual al'
        },
        {
            value: 'Menor que',
            text: 'Menor que'
        }
    ];

    inequalityTypes = [
        {
            value: 'Igual al',
            text: 'Igual al'
        },
        {
            value: 'Mayor que',
            text: 'Mayor que'
        },
        {
            value: 'Mayor o igual al',
            text: 'Mayor o igual al'
        },
        {
            value: 'Menor que',
            text: 'Menor que'
        },
        {
            value: 'Menor o igual a',
            text: 'Menor o igual a'
        }
    ];

    timeTypes = [
        {
            value: 'Horas',
            text: 'Horas'
        },
        {
            value: 'Minutos',
            text: 'Minutos'
        }
    ]

    dataCondition: DataCondition = {
        type: null,
        value: null
    };

    category: number = null;
    condition: number = null;

    text: string;
    data: any;
    textResult: string;
    textConditionResult: string;

    constructor(
        private categories: Categories
    ) { }

    actionEmit(data) {
        this[data]();
    }

    nextStep() {
        if(3 === this.step) {
            const conditionToSend: ConditionToSend = {
                category_id: this.category,
                condition_id: this.condition,
                data: this.dataCondition,
                text: this.textResult
            }
            this.buttonAction(conditionToSend)
        } else {
            switch(this.step) {
                case 1:
                    if(this.category) {
                        this.step++;
                    }
                    return;
                case 2:
                    if(this.condition) {
                        if((this.category == 2 && this.condition == 3) || (this.category == 3 && this.condition != 3)) {
                            this.step++;
                        } else if(this.dataCondition.value && this.dataCondition.type) {
                            this.step++;
                        }
                    }
                    break;
            }
            
        }
    }
    
    previousStep() {
        if(this.step  <= 1){
            return;
        }
        
        switch(this.step) {
            case 2:
                this.condition = null;
                this.dataCondition = {
                    type: null,
                    value: null
                }
                break;
        }
        
        this.step--;
    }

    changeCondition() {
        const condition = this.categories.getCategory(this.category, this.condition);
        
        this.text = condition.text;
        this.data = this[condition.data];
        this.textConditionResult = condition.textResult;
    }

    generateCondition(): string {
        this.textResult =  this.textConditionResult
            .replace('%value%', this.dataCondition.value)
            .replace('%type%', this.dataCondition.type);

        return this.textResult;
    }

    closeModalF() {
        this.closeModal();
    }

}