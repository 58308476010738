<div style="background-image: url('/assets/images/bg-auth-2-opacity.jpg'); background-size: cover;"
     class="min-h-screen">
    <div class="min-h-screen w-full lg:max-w-5xl mx-auto flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row items-center justify-center py-12 px-4 ">
            <div class="w-full mx-0 md:mx-12 md:w-1/2 space-y-8 md:space-y-10 mb-8 md:mb-0">
                <img class="h-12 w-auto text-left" src="/assets/images/logo-horizontal.png" alt="Bizdata">
                <p class="text-white text-justify">
                    <strong>Inteligencia de Localización y Telemetría</strong> basada en tecnologías de proximidad para
                    entornos <strong>indoor y outdoor</strong>
                </p>
                <div>
                    <div class="flex items-center mb-8 text-white space-x-4">
                    <h2 class="flex text-white font-bold text-4xl">
                        Log in
                    </h2>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                    </div>
                    <p class="text-white text-justify">
                        Acceda a su espacio de trabajo y comience a explorar cómo transformamos los datos de
                        localización y
                        ubicación de sus activos críticos y personas de interés en información útil con la nueva
                        <strong>plataforma
                            Bizdata</strong> nativa en la nube
                    </p>
                    <p class="text-white text-justify mt-8 md:mt-24">
                        © BizData 2022
                    </p>
                </div>
                
            </div>
            <div class="w-full mx-0 md:mx-12 md:w-1/2 bg-white p-6 rounded-md">
                <h2 class="mt-6 text-left text-xl font-extrabold text-bizdata-800">{{formTitle}}</h2>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>