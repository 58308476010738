import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CurrentFilter } from "../interfaces/filter.interface";


@Injectable({
    providedIn: 'root'
})
export class InfraService {

    private setDeviceSelectedSubject = new Subject<any>();
    public setDeviceSelected$ = this.setDeviceSelectedSubject.asObservable();
    
    constructor(
        private http: HttpClient
    ) {
       
    }

    getInfo(type:string, filter: CurrentFilter[] = null, pageNumber = 1, pageSize = 15, sortOrder = 'asc'):  Observable<any> 
    {
        const url = `${environment.apiUrl}/${type}`;

        let params: HttpParams = new HttpParams();
        params = params.set('sortOrder', sortOrder);
        params = params.set("page[number]", pageNumber);
        params = params.set("page[size]", pageSize);

        if(filter) {
            filter.forEach((filter) => {
                params = params.set(`filter[${filter.name}]`, filter.value);
            });
        }

        return this.http.get(url, {
            params: params
        }).pipe(
            map(res =>  {
                return {
                    'data': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }

    listInfoFilters(type: string) {
        const url = `${environment.apiUrl}/${type}/filters`;
        return this.http.get(url).pipe(
            map(res =>  {
                return res['filters'];
            })
        );
    }

    setDeviceSelected(device, type) {
        this.setDeviceSelectedSubject.next({ device: device, type: type });
    }

    getFreeHw(type: string):  Observable<any>  {
        const url = `${environment.apiUrl}/${type}/free`;

        return this.http.get(url).pipe(
            map(res =>  {
                return {
                    'data': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }
}