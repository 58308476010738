import { OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy {

    isOpen = false;
    isOpenMobile = true;
    showSmallNavBar = false;
    user: User;

    photo: string;

    private subscription = new Subscription();

    constructor(
        public authService: AuthService,
        private router: Router,
        private authGeneralService: AuthenticationGeneralService
    ) {
        this.user = this.authGeneralService.getUser();

        this.photo = this.user.photo ? this.user.photo : 'assets/images/svg/user-circle.svg';
        
    }

    ngOnInit(): void {
        
    }

    disabled() {
        this.isOpen = false;
    }

    logout() {
        this.subscription.add(this.authService.logout().subscribe(() => this.router.navigate(['/login'])));
    }



    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}