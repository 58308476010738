<!-- menu mobile -->
<div class="h-screen flex overflow-hidden bg-bizdata-700">
    <div [ngClass]="{ hidden:isOpenMobile}" class="lg:hidden">
        <div class="fixed inset-0 flex" style="z-index: 99999;">

            <div class="fixed inset-0">
                <div class="absolute inset-0 bg-gray-600 opacity-75">

                </div>
            </div>

            <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-bizdata-700">
                <div class="absolute top-0 right-0 -mr-14 p-1">
                    <button (click)="isOpenMobile= !isOpenMobile"
                        class="flex items-center justify-center h-12 w-12 rounded-full
                        focus:outline-none focus:bg-gray-600"
                        aria-label="Close sidebar">
                        <img src="assets/images/svg/x-white.svg" class="w-6 h-6" />
                    </button>
                </div>

                <div class="items-center flex-shrink-0 px-4">
                    <h3 class="text-bizdata-sansivera text-center">
                        <img _ngcontent-oqg-c58="" src="assets/images/logo-horizontal.png" alt="Bizdata" class="h-8 w-auto">
                    </h3>
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-bizdata space-y-1">
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <span class="pl-6" (click)="showModal()">{{ 'menu.home' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                    </svg>
                                    <span class="pl-6">{{ 'menu.analytics' | translate }}</span>
                                </div>
                        </a>
                        <a class="group text menu rounded-md" [routerLink]="['/tracking']" routerLinkActive="active">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                    <span class="pl-6">{{ 'menu.tracking' | translate }}</span>a
                                </div>
                        </a>
                        <a class="group text menu rounded-md">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                    </svg>
                                    <span class="pl-6">{{ 'menu.routes' | translate }}</span>
                                </div>
                        </a>
                        <a class="group text menu rounded-md">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                    </svg>
                                    <span class="pl-6">{{ 'menu.sensors' | translate }}</span>
                                </div>
                        </a>
                        <a class="group text menu rounded-md">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                    </svg>
                                    <span class="pl-6">{{ 'menu.process' | translate }}</span>
                                </div>
                        </a>
                        <a class="group text menu rounded-md active" [routerLink]="['/management']" routerLinkActive="active">
                                <div class="menu-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <span class="pl-6">{{ 'menu.management' | translate }}</span>
                                </div>
                        </a>
                    </nav>
                </div>

                <div class="flex-col flex border-t border-bizdata pt-4">
                    <p class="border-b text-center text-gray-400">{{ 'menu.more' | translate }}</p>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                                </svg>
                            </div>
                            {{ 'menu.base' | translate }}
                        </a>
            
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                </svg>
                            </div>
                            {{ 'menu.dev' | translate }}
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            {{ 'menu.contact' | translate }}
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                </svg>
                            </div>
                            {{ 'menu.policy' | translate }}
                        </a>
                        <p class="text-gray-400 text-center text-xs">
                            © BizData 2022
                        </p> 
                </div>
            </div>

            <div class="flex-shrink-0 w-14">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>

        </div>
    </div>

    <div id="modal-container" class="hidden"></div>
    <div id="overlay" (click)="closeModal()" class="hidden"></div>

    <!-- menu desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">

        <!-- long menu -->
        <div class="flex flex-col w-64" *ngIf="!showSmallNavBar">

            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow bg-bizdata pt-5 pb-4 overflow-y-auto">
                <div class="items-center flex-shrink-0 px-4">
                    <h3 class="text-white text-center" (click)="showModal()">
                        <img _ngcontent-oqg-c58="" src="assets/images/logo-horizontal.png" alt="Bizdata" class="h-8 w-auto">
                    </h3>
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-bizdata space-y-1">
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <span class="pl-6" (click)="showModal()">{{ 'menu.home' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.analytics' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md" [routerLink]="['/tracking']" routerLinkActive="active">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.tracking' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.routes' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.sensors' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.process' | translate }}</span>
                            </div>
                        </a>
                        <a class="group text menu rounded-md" [routerLink]="['/managements']" routerLinkActive="active">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span class="pl-6">{{ 'menu.management' | translate }}</span>
                            </div>
                        </a>
                    </nav>
                </div>

                <div class="flex-col flex border-t border-bizdata-stone pt-4">
                    <nav class="flex-1 px-2 space-y-1">
                        <p class="border-b text-center text-gray-400">{{ 'menu.more' | translate }}</p>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                                </svg>
                            </div>
                            {{ 'menu.base' | translate }}
                        </a>
            
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                </svg>
                            </div>
                            {{ 'menu.dev' | translate }}
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            {{ 'menu.contact' | translate }}
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                </svg>
                            </div>
                            {{ 'menu.policy' | translate }}
                        </a>
                        <p class="text-gray-400 text-center text-xs">
                            © BizData 2022
                        </p> 
                    </nav>
                </div>
            </div>
        </div>

        <!-- short menu -->
        <div class="flex flex-col w-16" *ngIf="showSmallNavBar">

            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow bg-bizdata pt-5 pb-4
                overflow-y-auto">

                <div class="flex-shrink-0 px-4">
                    BizData
                </div>

                <div class="mt-5 flex-1 flex flex-col">
                    <nav class="flex-1 px-2 bg-bizdata space-y-1 tooltip">
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md active" [routerLink]="['/tracking']" routerLinkActive="active">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md active" [routerLink]="['/management']" routerLinkActive="active">
                            <div class="menu-div">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>
                        </a>
                    </nav>
                </div>

                <div class="flex-col flex border-t border-bizdata-stone pt-4">
                    <nav class="flex-1 px-2 bg-bizdata space-y-1">
                        <p class="border-b text-center text-gray-400">{{ 'menu.more' | translate }}</p>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                                </svg>
                            </div>
                        </a>
            
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </a>
                        <a class="group text menu rounded-md">
                            <div class="flex items-center mr-3
                                p-1.5 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                </svg>
                            </div>
                        </a>
                        <p class="text-gray-400 text-center text-xs">
                            © BizData 2022
                        </p>   
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

