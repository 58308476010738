import { Component, Input, OnInit, Output } from "@angular/core";
import { InfraService } from "src/app/shared/services/infra.service";


@Component({
    selector: 'app-floor-modal-hw',
    templateUrl: './floor-hw.component.html',
    styleUrls: ['./floor-hw.component.scss']
})

export class FloorModalHwComponent implements OnInit {
    
    @Input() type: string;
    @Input() title: string;
    @Output() closeModal;

    devices = [];
    constructor(
        private infraService: InfraService
    ){

    }

    ngOnInit(): void {
        this.infraService.getInfo(this.type).subscribe(
            (response) => {
                this.devices = response['data'].filter(device => device.attributes.zone === null).
                    map(device => {
                        return {
                            id: device.id,
                            name: device.attributes.name
                        }
                    }
                );
            }
        )
    }
    
    selectedDevice(device) {
        this.infraService.setDeviceSelected(device, this.type);
        this.closeModalF();
    }

    buyHW() {
        window.open('https://bizdata.io/contacto/', '_blank');
    }

    closeModalF() {
        this.closeModal();
    }

}