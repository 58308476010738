import { Component, OnInit, NgZone, Input } from "@angular/core";
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import booleanContains from '@turf/boolean-contains';
import { MapLeafletService } from "./map-leaflet.service";
import 'leaflet.heat/dist/leaflet-heat.js'



@Component({
    selector: 'app-leaflet-map',
    templateUrl: './map-leaflet.component.html',
    styleUrls: ['./map-leaflet.component.scss']
})

export class LeafletMapComponent implements OnInit {

    map;
    controls;
    tileLayer;

    @Input() url: string;
    @Input() width: number;
    @Input() height: number;
    @Input() onlyOneFeature;
    
    private typeMarker;
    private currentZone;

    constructor(
         private ngZone: NgZone,
         private mapLeafletService: MapLeafletService
    ) { 
        const iconRetinaUrl = 'assets/marker-icon-2x.png';
        const iconUrl = 'assets/marker-icon.png';
        const shadowUrl = 'assets/marker-shadow.png';
        const iconDefault = L.icon({
        iconRetinaUrl,
        iconUrl,
        shadowUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
        });
        L.Marker.prototype.options.icon = iconDefault;
    }

    
    public ngOnInit(): void { 
        this.ngZone.runOutsideAngular(()=>{
			this.generateMap(this.url)
		});

        // print the controls in the map
        this.mapLeafletService.addControl$.subscribe(
            (response) => {
                this.map.pm.addControls(response);
            }
        );

        // clean al layers (except url)
        this.mapLeafletService.mapClear$.subscribe(
            () => this.cleanMap()
        );

        // add a feature from backend
        this.mapLeafletService.addFeature$.subscribe(
            (response) => {
                let marker = L.geoJSON(response.feature);
                
                if(response.feature.geometry.type === 'Point') {
                    const properties = response.feature.properties;
                    marker.bindPopup(properties.name).openTooltip();
                }
                
                marker.addTo(this.map);

            }
        );

        // set type marker to create
        this.mapLeafletService.typeMarker$.subscribe(
            (typeMarker) => {
                this.typeMarker = typeMarker;
            }
        );

        // current zone who created a marker
        this.mapLeafletService.currentZone$.subscribe(
            (currentZone) => {
                this.currentZone = currentZone;
            }
        );

        this.mapLeafletService.circleFeature$.subscribe(
            (response) => {
                const latLong = [response.latitude, response.longitude];
                const radius = response.radius;
 
                radius.forEach((r: number) => {
                    let circle = new L.Circle(latLong, r, {
                        color: this.radiusColor(r),
                        opacity:.5, 
                        stroke: false
                    });
                    
                    circle.addTo(this.map);
                });

                if(response.ranges) {
                    this.createRanges(response.latitude, response.longitude, response.ranges);
                }
                
                let marker = new L.Marker(latLong);
                marker.addTo(this.map);
            }
        );

        this.mapLeafletService.updateLayer$.subscribe(
            (response) => this.updateLayer(response)
        )

        this.mapLeafletService.pointsFeature$.subscribe(
            (response) => this.pointsFeature(response)
        )
    }

    private createRanges(latitude, longitude, ranges)
    {
        ranges.forEach(range => {
            let icon = new L.icon({
                iconRetinaUrl: range.image,
                iconUrl: range.image,
                shadowUrl: 'assets/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41]
            });

            let marker = new L.Marker([latitude-range.meters, longitude], {icon: icon});
            
            marker.addTo(this.map);
        });
    }

    private radiusColor(radius: number) {
        if(radius < 3){
            return 'green';
        }

        if(radius > 19) {
            return 'red';
        }

        return 'yellow';
    }

    private tileLayerWMS(url, options) {
        let TileLayerWMS = L.TileLayer.extend({
            options: {
                width: -1, // Must be set by user, max zoom image width
                height: -1, // Must be set by user, max zoom image height
                tileGroupPrefix: 'TileGroup',
                tilesPerTileGroup: 256
            },
        
            initialize: function (url, options) {
                L.TileLayer.prototype.initialize.call(this, url, options);
        
                // Replace with automatic loading from ImageProperties.xml
                if (this.options.width < 0 || this.options.height < 0) {
                    throw new Error('The user must set the Width and Height of the Zoomify image');
                }
            },
        
            beforeAdd: function (map) {
                var imageSize = L.point(this.options.width, this.options.height);
        
                // Build the zoom sizes of the pyramid and cache them in an array
                this._imageSize = [imageSize];
                this._gridSize = [this._getGridSize(imageSize)];
        
                // Register the image size in pixels and the grid size in # of tiles for each zoom level
                while (imageSize.x > this.options.tileSize || imageSize.y > this.options.tileSize) {
                    imageSize = imageSize.divideBy(2).ceil();
                    this._imageSize.push(imageSize);
                    this._gridSize.push(this._getGridSize(imageSize));
                }
        
                // We built the cache from bottom to top, but leaflet uses a top to bottom index for the zoomlevel,
                // so reverse it for easy indexing by current zoomlevel
                this._imageSize.reverse();
                this._gridSize.reverse();
        
                // Register our max supported zoom level
                var maxNativeZoom = this._gridSize.length - 1;
                this.options.maxNativeZoom = maxNativeZoom;
        
                // Register our bounds for this zoomify layer based on the maximum zoom
                var maxZoomGrid = this._gridSize[maxNativeZoom],
                maxX = maxZoomGrid.x * this.options.tileSize,
                maxY = maxZoomGrid.y * this.options.tileSize,
                southEast = map.unproject([maxX, maxY], maxNativeZoom);
                this.options.bounds = new L.LatLngBounds([[0, 0], southEast]);
        
                L.TileLayer.prototype.beforeAdd.call(this, map);
            },
        
            // Calculate the grid size for a given image size (based on tile size)
            _getGridSize: function (imageSize) {
                var tileSize = this.options.tileSize;
                return L.point(Math.ceil(imageSize.x / tileSize), Math.ceil(imageSize.y / tileSize));
            },
        
            // Extend the add tile function to update our arbitrary sized border tiles
            _addTile: function (coords, container) {
                // Load the tile via the original leaflet code
                L.TileLayer.prototype._addTile.call(this, coords, container);
        
                // Get out imagesize in pixels for this zoom level and our grid size
                var imageSize = this._imageSize[this._getZoomForUrl()],
                    gridSize = this._gridSize[this._getZoomForUrl()];
        
                // The real tile size (default:256) and the display tile size (if zoom > maxNativeZoom)
                var realTileSize = L.GridLayer.prototype.getTileSize.call(this),
                    displayTileSize = L.TileLayer.prototype.getTileSize.call(this);
        
                // Get the current tile to adjust
                var key = this._tileCoordsToKey(coords),
                    tile = this._tiles[key].el;
        
                // Calculate the required size of the border tiles
                var scaleFactor = L.point(	(imageSize.x % realTileSize.x),
                                            (imageSize.y % realTileSize.y)).unscaleBy(realTileSize);
        
                // Update tile dimensions if we are on a border
                if ((imageSize.x % realTileSize.x) > 0 && coords.x === gridSize.x - 1) {
                    tile.style.width = displayTileSize.scaleBy(scaleFactor).x + 'px';
                }
        
                if ((imageSize.y % realTileSize.y) > 0 && coords.y === gridSize.y - 1) {
                    tile.style.height = displayTileSize.scaleBy(scaleFactor).y + 'px';
                }
            },
        
            // Construct the tile url, by inserting our tilegroup before we template the url
            getTileUrl: function (coords) {
                // Set our TileGroup variable, and then let the original tile templater do the work
                this.options.g = this.options.tileGroupPrefix + this._getTileGroup(coords);
        
                // Call the original templater
                return L.TileLayer.prototype.getTileUrl.call(this, coords);
            },
        
            // Calculates the TileGroup number, each group contains 256 tiles. The tiles are stored from topleft to bottomright
            _getTileGroup: function (coords) {
                var zoom = this._getZoomForUrl(),
                    num = 0,
                    gridSize;
        
                // Get the total number of tiles from the lowest zoom level to our zoomlevel
                for (var z = 0; z < zoom; z++) {
                    gridSize = this._gridSize[z];
                    num += gridSize.x * gridSize.y;
                }
        
                // Add the remaining tiles from this zoom layer to the running total of tiles
                num += coords.y * this._gridSize[zoom].x + coords.x;
                return Math.floor(num / this.options.tilesPerTileGroup);
            },
        
            getBounds: function () {
                return this.options.bounds;
            }
        
        });
        
        return new TileLayerWMS(url, options);
    }

    private generateMap(url: string) {
        this.controls = this.mapLeafletService.getControls();
        
        this.map = L.map('mapid', {
            maxZoom: 5, //Does not matter anymore, maxNativeZoom prevents loading of missing zoom levels
            minZoom: 0,
            crs: L.CRS.Simple //Set a flat projection, as we are projecting an image
        });
    
        this.tileLayer = this.tileLayerWMS(url, {
            width: this.width,
            height: this.height,
            attribution: '&copy; <a href="https://www.mapbox.com/contribute/">Mapbox</a> &copy; <a href="https://bizdata.io/sobre-nosotros/#:~:text=online%20y%20offline-,Sobre%20Nosotros,-El%20equipo%20de">Bizdata.io</a>'
        }).addTo(this.map);

        //Setting the view to our layer bounds, set by our Zoomify plugin
        this.map.fitBounds(this.tileLayer.getBounds());
        
		// add Leaflet-Geoman controls with some options to the map  
		this.map.pm.addControls(this.controls);

        this.map.on('pm:create', (e) => {
            
            const feature = e.layer.toGeoJSON();
            
            switch(this.mapLeafletService.getStep()) {
                case 2:
                    this.mapLeafletService.changeLine(feature);
                    break;
                case 3:
                    this.mapLeafletService.changePerimeter(feature);
                    break;
                case 4:
                    this.mapLeafletService.changeZone(feature);
                    break;
                case 5:
                    if(!booleanContains(this.currentZone, feature)) {
                        this.map.removeLayer(e.layer);
                    } else {
                        this.map.pm.disableDraw();
                        
                        feature.properties.type = this.typeMarker.type;
                        feature.properties.id = this.typeMarker.id;
                        this.mapLeafletService.addMarker(feature);
                        this.map.removeLayer(e.layer);
                    }
                break;
            }
            
            if(this.onlyOneFeature) {
                this.map.pm.addControls({  
                    removalMode: true,
                    drawPolyline: false,  
                    drawRectangle: false,  
                    drawPolygon: false,
                    drawMarker: false
                }); 
            }
        });

        // remove something
        this.map.on('pm:remove', (e) => {
            switch(this.mapLeafletService.getStep()) {
                case 2:
                    this.mapLeafletService.removeLine();
                    break;
                case 3:
                    this.mapLeafletService.removePerimeter();
                    break;
                case 4:
                    this.mapLeafletService.removeZone();
                    break;
                case 5:
                    const layer = e.layer.toGeoJSON();
                    
                    if(layer.geometry.type === 'Point') {
                        this.mapLeafletService.removeMarker(e.layer.toGeoJSON());
                    } else {
                        this.mapLeafletService.addFeatureToMap({feature: e.layer.toGeoJSON()});
                    }
                    
                    break;
            }

            if(this.onlyOneFeature) {
                this.map.pm.addControls({
                    removalMode: false,
                    ...this.mapLeafletService.getControls()}
                ); 
            }
        });
    }

    updateLayer(response) {
        this.tileLayer.setUrl(response.url);
    }

    pointsFeature(response) {
        L.heatLayer(response, {radius: 25, gradient: {0.4: 'blue', 0.65: 'lime', 1: 'red'}}).addTo(this.map);
    }

    private cleanMap() {
        if(typeof this.map === 'object') {
            this.map.eachLayer((layer) => {
                if(!layer._url){
                    this.map.removeLayer(layer);
                }
            });
        }
    }
}