<app-auth-base formTitle="{{'auth.reset.title' | translate}}">
    <form class="mt-8 space-y-6" [formGroup]="resetForm">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm space-y-6">
            <div>
                <label for="email-address" class="sr-only">{{'auth.email' | translate}}</label>
                <input formControlName="email" type="email" autocomplete="email" required
                       class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-bizdata-800 focus:border-bizdata-800 focus:z-10 sm:text-sm"
                       placeholder="{{'auth.email' | translate}}">
                <ng-container *ngIf="f.email.errors">
                    <app-error-form *ngIf="f.email.errors.email" error="{{'errors.email' |
                        translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div>
                <label for="password" class="sr-only">{{'auth.password' | translate}}</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <input formControlName="password" [type]="fieldTextType ? 'text' : 'password'" required
                       class="appearance-none rounded-none rounded-l-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-bizdata-800 focus:border-bizdata-800 focus:z-10 sm:text-sm"
                       placeholder="{{'auth.password' | translate}}">
                    <button (click)="toggleFieldTextType(1)" type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <svg *ngIf="fieldTextType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <svg *ngIf="!fieldTextType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                        </svg>
                    </button>
                </div>
                
                <ng-container *ngIf="f.password.errors">
                    <app-error-form *ngIf="f.password.errors.minlength"
                        error="{{'errors.minLength' | translate:passwordLength}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div>
                <label for="password" class="sr-only">{{'auth.reset.confirm_password' | translate}}</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <input formControlName="password_confirmation" [type]="fieldTextTypeC ? 'text' : 'password'" required
                        class="appearance-none rounded-none rounded-l-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-bizdata-800 focus:border-bizdata-800 focus:z-10 sm:text-sm"
                        placeholder="{{'auth.password' | translate}}">
                    <button (click)="toggleFieldTextType(2)" type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        <svg *ngIf="fieldTextTypeC" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <svg *ngIf="!fieldTextTypeC" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />                        
                        </svg>
                    </button>
                </div>
                <ng-container *ngIf="f.password_confirmation.errors">
                    <app-error-form *ngIf="f.password_confirmation.errors.minlength"
                        error="{{'errors.minLength' | translate:passwordLength}}">
                    </app-error-form>
                </ng-container>
                <ng-container *ngIf="notMatchPassword">
                    <app-error-form error="{{'errors.reset.noMatchPasswords' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>

        <div class="mb-6" *ngIf="error">
            <app-error-form *ngIf="422 === error" error="{{'auth.forgot.error' | translate}}">
            </app-error-form>
        </div>

        <div class="mb-6" *ngIf="success">
            <p class="text-green-500 text-xs italic">
                {{'auth.reset.success' | translate}}
            </p>
        </div>

        <div>
            <app-auth-button title="{{'auth.reset.button' | translate}}" [disabled]="disabledButton" (submit)="onSubmit()"></app-auth-button>
        </div>

    </form>
</app-auth-base>