import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorFormComponent } from '../components/form/error/error.component';
import { LabelFormComponent } from '../components/form/label/label.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from '../components/table/table.component';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { BreadcumbComponent } from '../components/breadcumb/breadcumb.component';
import { LeafletMapComponent } from '../components/leaflet/map-leaflet.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { FilterComponent } from '../components/filter/filter.component';

@NgModule({
    declarations: [
        ErrorFormComponent,
        LabelFormComponent,
        TableComponent,
        FilterComponent,
        SnackbarComponent,
        BreadcumbComponent,
        LeafletMapComponent,
        DashboardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule
    ],
    exports: [
        DashboardComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorFormComponent,
        LabelFormComponent,
        TranslateModule,
        TableComponent,
        FilterComponent,
        HttpClientModule,
        SnackbarComponent,
        BreadcumbComponent,
        LeafletMapComponent,
        
    ],
    providers: []
})
export class SharedModule {
    /**/
}
