import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationGeneralService } from '../services/auth-general.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    headerName = 'X-XSRF-TOKEN';
    constructor(
        private auth: AuthenticationGeneralService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.getToken();

        req = req.clone({
            withCredentials: true,
        });

        req = req.clone({
            headers: req.headers.set('Accept', 'application/vnd.api+json')
        });
        

        if (token) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });
        }

        if ('GET' === req.method || 'HEAD' === req.method) {
            return next.handle(req).pipe(
                catchError(err => this.catchError(err))
            )
        }

        if ('POST' === req.method || 'PATCH' === req.method) {
            req = req.clone({
                headers: req.headers.set('content-type', 'application/vnd.api+json')
            });
        }

        if (token !== null && !req.headers.has(this.headerName)) {
            req = req.clone({ headers: req.headers.set(this.headerName, token) });
        }

        return next.handle(req).pipe(
            catchError(err => this.catchError(err))
        )
    }

    public catchError(err: any)
    {
        return new Observable<HttpEvent<any>>(observer => {
            if (err instanceof HttpErrorResponse) {
                const errResp = <HttpErrorResponse>err;
                if(errResp.status === 401){
                    this.auth.logout();
                }
            }
            observer.error(err);
            observer.complete();
          })
    }
}