import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-modal-info-options',
	templateUrl: './modal-info-options.component.html'
})

export class ModalInfoOptionsComponent {

	@Input() title: string;
	@Input() options: any;
	@Output() closeModal = new EventEmitter<void>();
	@Output() actionOuput = new EventEmitter<any>();

	closeModalF() {
		this.closeModal.emit();
	}

	buttonAction(data: any) {
		this.actionOuput.emit(data)
	}
}