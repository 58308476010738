import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ModalInfoComponent } from 'src/app/shared/components/modals/info/modal-info.component';
import { ErrorService } from 'src/app/shared/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {

	loginForm: FormGroup;
	submitted = false;
	passwordLength = { number: 8 };
	error: number;
	disabledButton: boolean = true;
	fieldTextType: boolean;
	errorMessage: string;
	
	private debounce: number = 800;
	private subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private builder: FormBuilder,
		private authService: AuthService,
		private modalService: ModalService,
		private errorService: ErrorService
	) { }

	closeModal() {
        this.modalService.destroy();
    }

	ngOnInit(): void {
		if (this.route.snapshot.queryParams['result']) {
            this.modalService.init(ModalInfoComponent, 
				{
					"title": "Reset password",
					"text": "Su contraseña se ha actualizado correctamente.",
					"text_button_cancel": "Cerrar"
				}
				, { closeModal: this.closeModal.bind(this), buttonAction: this.closeModal.bind(this) });
        }
		this.loginForm = this.builder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(8)]],
		});

		this.loginForm.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(
				() => {
					this.disabledButton = true;
					if(this.loginForm.valid) {
						this.disabledButton = false;
                    }
            	}
            );
	}

	get f() { return this.loginForm.controls; }

	submit() {

		this.submitted = true;

		if (this.loginForm.invalid) {
			this.disabledButton = true;
			return;
		} 


		this.subscription.add(
			this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value).subscribe(
				() => {
					this.authService.getUser().subscribe(
						() => {
							this.router.navigate(['/']);
						}, (error: HttpErrorResponse) => {
							this.errorMessage = this.errorService.manageError(error);
						}
					)
				}, (error: HttpErrorResponse) => {
					this.errorMessage = this.errorService.manageError(error);
				}
			)
		);
		
	};

	toggleFieldTextType() {
		this.fieldTextType = !this.fieldTextType;
	}


	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}