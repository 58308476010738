<div class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" *ngIf="filters">
    <div class="sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
            <div class="relative inline-block text-right">
                <div>
                    <span (click)="showColumns = false; showFilters = !showFilters; showFiltersValue = false" class="cursor-pointer inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-bizdata-100 text-gray-800"> Filtros </span>
                    <ng-container *ngIf="currentFilters.length > 0">
                        <span *ngFor="let current of currentFilters; let i = index" class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700">
                            {{current.nameText}}: {{current.valueText}}
                            <button (click)="deleteCurrentFilter(i)" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
                                <span class="sr-only">Remove small option</span>
                                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                                </svg>
                            </button>
                        </span>
                    </ng-container>
                </div>

                <div *ngIf="showFilters" [@enterAnimation] class="z-10 origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <a (click)="filterValue(filter)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:bg-bizdata-600 hover:text-white" role="menuitem" tabindex="-1" id="menu-item-0" *ngFor="let filter of filters">
                            {{filter.nameTranslate | translate}}
                        </a>
                    </div>
                </div>

                <div *ngIf="showFiltersValue" [@enterAnimationValue]  class="z-10 origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <ng-container *ngIf="filter.type !== 'text'">
                            <a *ngFor="let option of filter.options; let i = index" (click)="selectFilterValue(option)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0" >
                                {{option.name}}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="filter.type === 'text'">
                            <div class="w-full flex rounded-md shadow-sm">
                                <input [(ngModel)]="filterText" type="text" name="company-website" id="company-website" 
                                class="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md rounded-r-0 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300">
                                <span (click)="selectFilterValue(filterText)" class="cursor-pointer inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> 
                                    <img class="w-6 h-6" src="assets/images/svg/check.svg" />
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>