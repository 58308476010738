<nav class="flex text-bizdata-green" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class=" items-center" *ngFor="let breadcumb of breadcumbs; let i = index"
        [ngClass]="{ 'inline-flex': !i, 'flex': i }">
            <a *ngIf="!i" [attr.href]="breadcumb.url ? breadcumb.url : null" class="inline-flex items-center text-md font-medium text-bizdata-green">
                {{breadcumb.title | translate:breadcumb.value}}
            </a>
            <ng-container *ngIf="i">
                <svg class="w-6 h-6 text-bizdata-green" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                <a [attr.href]="breadcumb.url ? breadcumb.url : null" class="ml-1 text-md font-medium text-bizdata-green md:ml-2">
                    {{breadcumb.title | translate:breadcumb.value}}
                </a>
            </ng-container>
        </li>
    </ol>
</nav>