<div class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" *ngIf="filters">
    <div class="sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
            <div class="relative inline-block text-right">
                <div>
                    <span (click)="showColumns = false; showFilters = !showFilters; showFiltersValue = false" class="cursor-pointer inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-bizdata-100 text-gray-800"> Filtros </span>
                    <ng-container *ngIf="currentFilters.length > 0">
                        <span *ngFor="let current of currentFilters; let i = index" class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700">
                            {{current.nameText}}: {{current.valueText}}
                            <button (click)="deleteCurrentFilter(i)" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
                                <span class="sr-only">Remove small option</span>
                                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                                </svg>
                            </button>
                        </span>
                    </ng-container>
                </div>

                <div *ngIf="showFilters" [@enterAnimation]  class="origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <a (click)="filterValue(filter)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:bg-bizdata-600 hover:text-white" role="menuitem" tabindex="-1" id="menu-item-0" *ngFor="let filter of filters">
                            {{filter.nameTranslate | translate}}
                        </a>
                    </div>
                </div>

                <div *ngIf="showFiltersValue" [@enterAnimationValue]  class="origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <ng-container *ngIf="filter.type !== 'text'">
                            <a *ngFor="let option of objectKeys(filter.options); let i = index" (click)="selectFilterValue(option)" class="cursor-pointer text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0" >
                                {{filter.options[option]}}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="filter.type === 'text'">
                            <div class="w-full flex rounded-md shadow-sm">
                                <input [(ngModel)]="filterText" type="text" name="company-website" id="company-website" 
                                class="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md rounded-r-0 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300">
                                <span (click)="selectFilterValue(filterText)" class="cursor-pointer inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> 
                                    <img class="w-6 h-6" src="assets/images/svg/check.svg" />
                                </span>
                            </div>
                            
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overflow-x-auto border shadow">
    <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
        <tr class="divide-x divide-gray-200">
            <ng-container *ngFor="let column of columns; let i=index">
                <th scope="col"
                    class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    [ngClass]="{ hidden:!column.visible}">
                    {{column.name}}
                </th>
            </ng-container>
            <th scope="col" class="text-center relative ">
                <svg (click)="showColumns = !showColumns; showFilters = false;" class="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>

                <ul *ngIf="showColumns" class="absolute right-0 z-10 mt-1 w-auto bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                    <li *ngFor="let column of columns;"
                        (click)="changeVisibilty(column)"
                        class="flex text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 focus:bg-bizdadta-600 hover:bg-bizdata-600 hover:text-white" role="option">
                        <span class="block truncate" [ngClass]="{ 'font-normal': !column.visible, 'font-semibold': column.visible }"> {{column.name}} </span>

                        <span *ngIf="column.visible" class="focus:text-white hover:text-white"
                              [ngClass]="{ 'text-white': !column.visible, 'text-bizdata': column.visible }"
                              class="absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <svg class="h-5 w-5 hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </li>
                </ul>
            </th>
        </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
        <ng-container *ngFor="let user of data;">
            <tr class="divide-x divide-gray-200">
                <ng-container *ngFor="let column of columns; let i=index">
                    <ng-container *ngIf="column.onlyRender; else td">
                        <td>
                            <div [innerHTML]="getVariable(user.attributes, column.column)"></div>
                        </td>
                    </ng-container>
                    <ng-template #td>
                        <td [ngClass]="{ hidden:!column.visible}" class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {{getVariable(user.attributes, column.column)}}
                        </td>
                    </ng-template>
                    
                </ng-container>
                <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                    <div class="relative mx-1 px-1 mb-1 md:mb-0" id="button_mlist">
                        <div  class="inline-block" *ngFor="let action of actions">
                            <ng-container *ngIf="1 === action.type">
                                <a class="px-2 py-1 cursor-pointer" (click)="generateUrl(action.action, user.attributes)">
                                    <img class="w-6 h-6" src="assets/images/svg/{{action.icon}}" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="2 === action.type">
                                <a class="px-2 py-1 cursor-pointer" (click)="generateAction(action.action, user.id)">
                                    <img class="w-6 h-6" src="assets/images/svg/{{action.icon}}" />
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>

    <div class="px-4 py-3 bg-gray-50 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex-1 flex justify-between md:hidden">
            <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
            <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium">{{meta.from}}</span>
                    to
                    <span class="font-medium">{{meta.to}}</span>
                    of
                    <span class="font-medium">{{meta.total}}</span>
                    results
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <a *ngIf="!first_page" (click)="nextPage('-')" class="cursor-pointer px-2 py-2 rounded-l-md bg-white shadow hover:bg-bizdata-light
                        pagination">
                        <img class="h-5 w-5" src="assets/images/svg/chevron-left.svg" alt="chevron">
                    </a>

                    <ng-container *ngFor="let link of links; let i=index">
                        <a *ngIf="showPerPage(i)" [ngClass]="{ 'rounded-l-md': i == 1,'bg-bizdata-light':page == i}" (click)="nextPage(link.label)" class="cursor-pointer -ml-px shadow
                            px-4 py-2 bg-white pagination hover:bg-bizdata-light">
                            {{link.label}}
                        </a>
                    </ng-container>

                    <a *ngIf="!last_page" (click)="nextPage('+')" class="cursor-pointer -ml-px px-2 py-2 bg-white rounded-r-md shadow
                        pagination hover:bg-bizdata-light" >
                        <img class="h-5 w-5" src="assets/images/svg/chevron-right.svg"
                             alt="chevron">
                    </a>
                </nav>
            </div>
        </div>
    </div>
</div>