import { Component, OnInit, OnDestroy } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-reset',
	templateUrl: './reset.component.html',
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
    
    resetForm: FormGroup;
    disabledButton: boolean = true;
    error: number;
    success: boolean = false;
    notMatchPassword: boolean = false;
    passwordLength = { number: 8 };
    fieldTextType: boolean;
    fieldTextTypeC: boolean;

    private token: string;
    private debounce: number = 800;
	private subscription = new Subscription();
    
    constructor(
        private router: Router,
		private route: ActivatedRoute,
		private builder: FormBuilder,
		private authService: AuthService
	) {
        
    }

    ngOnInit(): void {
        
        this.route.queryParams.subscribe(
            params => {
                this.token = params.token;
            }
        );
        
        this.resetForm = this.builder.group({
            email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(8)]],
			password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
		});

        this.resetForm.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(
				() => {
					this.disabledButton = true;
					if(this.resetForm.valid) {
						this.disabledButton = false;
                    }
            	}
            );
    }

    get f() { return this.resetForm.controls; }

    onSubmit(){

        let form = this.resetForm.value;

        if(form.password !== form.password_confirmation) {
            this.disabledButton = true;
            this.notMatchPassword = true;
            return;
        }


        if (this.resetForm.invalid) {
            this.disabledButton = true;
			return;
		}  

        form.token = this.token;

        this.subscription.add(
			this.authService.resetPassword(form).subscribe(
				() => {
                    this.resetForm.reset();
                    this.error = null;
                    this.disabledButton = true;
                    this.success = true;
                    this.router.navigate(['/login'], { queryParams: { result: 'reset' } });
                }
            )
        );
    }

    toggleFieldTextType(type: number) {
        if(1 === type)
		    this.fieldTextType = !this.fieldTextType;
        else
            this.fieldTextTypeC = !this.fieldTextTypeC;
	}

    ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}