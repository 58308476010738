<app-modal-data [title]="title" (closeModal)="closeModalF()">
    <div data class="w-full p-2" >
        <ul role="list" class="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3" *ngIf="devices.length; else noDevices">
            <li *ngFor="let device of devices" (click)="selectedDevice(device)"
                class="cursor-pointer col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                <div class="w-full flex items-center justify-between p-6
                    space-x-6">
                    <div class="flex-1 truncate">
                        <p class="mt-1 text-gray-500 text-sm truncate">
                            {{device.name}}
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <ng-template #noDevices>
            <div class="mt-6 text-center">   
                <h1>No tiene ningun {{type}} en su cuenta</h1>
                <h2> Si no tiene ningún dispoitivo, compre alguno:</h2>
                <button class="button" (click)="buyHW()">COMPRAR DISPOSITIVOS</button>
            </div>
        </ng-template>
    </div>
</app-modal-data>