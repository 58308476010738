import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PlaceService {

    private deleteSubject = new Subject<any>();
    public deleteState = this.deleteSubject.asObservable();

    private deleteBuildingSubject = new Subject<any>();
    public deleteBuildingState = this.deleteBuildingSubject.asObservable();

    private deleteBuildingFloorSubject = new Subject<any>();
    public deleteBuildingFloorState = this.deleteBuildingFloorSubject.asObservable();

    public currentPlace;

    constructor(
        private http: HttpClient
    ) {
       
    }

    get place() { return this.currentPlace }

    getPlace(place: string){
        return this.http.get(`${environment.apiUrl}/places/${place}`).pipe(
            map(res =>  {
                return res['data'].attributes;
                this.currentPlace = res;
            })
        );
    }

    listPlaces(pageNumber = 1, pageSize = 15, 
        filter = '', sortOrder = 'asc'):  Observable<any> {

        return this.http.get(`${environment.apiUrl}/places`, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set("page[number]", pageNumber)
                .set("page[size]", pageSize)
        }).pipe(
            map(res =>  {
                return {
                    'places': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }

    createUpdatePlace(place, place_id) {
        const data = {
            data: {
                type: 'places',
                attributes: place
            }
        };

        if(place_id) {
            return this.http.patch(`${environment.apiUrl}/places/${place_id}`, data).pipe(
                map(res =>  {
                    return res;
                })
            );
        }
        return this.http.post(`${environment.apiUrl}/places`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    deletePlaceState(user_id) {
        this.deleteSubject.next({ user_id: user_id });
    }

    deletePlace(place_id) {
        return this.http.delete(`${environment.apiUrl}/places/${place_id}`).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    // building
    listBuildings(place_id, pageNumber = 1, pageSize = 15, 
        filter = '', sortOrder = 'asc'):  Observable<any> {

        return this.http.get(`${environment.apiUrl}/places/${place_id}/building`, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set("page[number]", pageNumber)
                .set("page[size]", pageSize)
        }).pipe(
            map(res =>  {
                return {
                    'buildings': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }

    getBuilding(place_id, building_id) {
        return this.http.get(`${environment.apiUrl}/places/${place_id}/building/${building_id}`).pipe(
            map(res =>  {
                return res['data'].attributes;
                this.currentPlace = res;
            })
        );
    }

    createUpdateBuilding(building, place_id, building_id) {
        const data = {
            data: {
                type: 'buildings',
                attributes: building
            }
        };

        if(building_id) {
            return this.http.patch(`${environment.apiUrl}/places/${place_id}/building/${building_id}`, data).pipe(
                map(res =>  {
                    return res;
                })
            );
        }
        return this.http.post(`${environment.apiUrl}/places/${place_id}/building`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    deletePlaceBuildingState(place_id) {
        this.deleteBuildingSubject.next({ place_id: place_id });
    }

    deletePlaceBuilding(place_id, building_id) {
        return this.http.delete(`${environment.apiUrl}/places/${place_id}/building/${building_id}`).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    // floors
    listFloors(place_id, building_id, pageNumber = 1, pageSize = 15, 
    filter = '', sortOrder = 'asc'):  Observable<any> {

        return this.http.get(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors`, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set("page[number]", pageNumber)
                .set("page[size]", pageSize)
        }).pipe(
            map(res =>  {
                return {
                    'floors': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }

    getFloor(place_id, building_id, floor_id) {
        return this.http.get(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}`).pipe(
            map(res =>  {
                return res['data'].attributes;
            })
        );
    }
    
    createBuildingFloor(place_id, building_id, floor) {
        const data = {
            data: {
                type: 'floors',
                attributes: floor
            }
        };

        return this.http.post(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    deletePlaceBuildingFloorState(floor_id) {
        this.deleteBuildingFloorSubject.next({ floor_id: floor_id });
    }

    deletePlaceBuildingFloor(place_id, building_id, floor_id) {
        return this.http.delete(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}`).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    createPlaceBuildingFloorLine(place_id, building_id, floor_id, feature) {
        const data = {
            data: {
                type: 'floors_line',
                attributes: feature
            }
        };

        return this.http.post(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/line`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    createPlaceBuildingFloorPerimeter(place_id, building_id, floor_id, feature) {
        const data = {
            data: {
                type: 'floors_perimeter',
                attributes: feature
            }
        };

        return this.http.post(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/perimeter`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    createOrUpdatePlaceBuildingFloorZone(place_id, building_id, floor_id, feature) {
        const data = {
            data: {
                type: 'floors_zone',
                attributes: feature
            }
        };

        let url = `${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/zone`;
        if(feature.id) {
            url += `/${feature.id}`;
            return this.http.patch(url, data).pipe(
                map(res =>  {
                    return res;
                })
            );
        } else {
            return this.http.post(url, data).pipe(
                map(res =>  {
                    return res;
                })
            );
        }
    }

    getZones(place_id, building_id, floor_id) {
        return this.http.get(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/zone`).pipe(
            map(res =>  {
                return {
                    'zones': res['data'].map(({ id, attributes}) => ({id:id, attributes: attributes })),
                    'meta':  res['meta']
                }
            })
        );
    }

    createHw(place_id, building_id, floor_id, zone_id, feature) {
        const data = {
            data: {
                type: 'floors_line',
                attributes: {
                    id: feature.properties.id,
                    type: feature.properties.type,
                    feature
                }
            }
        };

        return this.http.post(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/zone/${zone_id}/element`, data).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    removeHw(place_id, building_id, floor_id, zone_id, feature) {
        const data = {
            data: {
                type: 'floors_line',
                attributes: {
                    id: feature.id,
                    type: feature.type
                }
            }
        };
        return this.http.post(`${environment.apiUrl}/places/${place_id}/building/${building_id}/floors/${floor_id}/zone/${zone_id}/remove-element` , data).pipe(
            map(res =>  {
                return res;
            })
        );
    }
}