import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationGeneralService } from '../shared/services/auth-general.service';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthenticationGeneralService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (!this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}