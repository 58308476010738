import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationGeneralService } from 'src/app/shared/services/auth-general.service';
import { User } from 'src/app/shared/interfaces/user.interface';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private auth: AuthenticationGeneralService,
    ) {
       
    }

    login(email: string, password: string) {
        const body = {
            email,
            password,
        };
        return this.http
            .post(`${environment.apiUrl}/login`, body)
            .pipe(map((response: any) => {
                if (!response.error) {
                    this.auth.setToken(response.access_token);
                }
            }));
    }

    getUser() 
    {
        return this.http.get(`${environment.apiUrl}/me`)
        .pipe(map((response: any) => {
            const user: User = response.data.attributes;
            this.auth.setUser(user);
        }));
    }

    forgotPassword(email: string)
    {
        const body = {
            email
        };

        return this.http
            .post(`${environment.apiUrl}/forgot-password`, body)
            .pipe(map((response: any) => response));
    }

    resetPassword(form: string)
    {
        return this.http
            .post(`${environment.apiUrl}/reset-password`, form)
            .pipe(map((response: any) => response));
    }

    refreshToken()
    {
        return this.http
            .post(`${environment.apiUrl}/refresh-token`, {})
            .pipe(map((response: any) => response));
    }

    logout() {
        return this.http
            .post(`${environment.apiUrl}/logout`, {})
            .pipe(map(() => {
                this.auth.clearUser();
            }));
    }
}