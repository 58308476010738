import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../interfaces/user.interface';
import { Roles } from '../settings/role';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGeneralService {
    data;

    constructor(
        private router: Router, 
    ) {
        if (this.isLoggedIn()) {
            this.data = this.getUser();
        }
    }

    getToken(): any {
        return localStorage.getItem('token');
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getUser(): any {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    getUserVariable(name: any) {
        const user = this.getUser();
        return user[name];
    }

    setUser(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    updateUser(user: User) {
        let currentUser = this.getUser();

        const newUser = { ...currentUser, ...user };

        localStorage.setItem('currentUser', JSON.stringify(newUser));
    }

    isLoggedIn() {
        if (!this.getToken()) {
            return false;
        }

        return true;
    }

    clearUser() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
    }

    logout() {
        this.clearUser();
        this.router.navigate(['/login']);
    }
}
