import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Action } from "../../interfaces/action.interface";
import { Column } from "../../interfaces/column.interface";
import { CurrentFilter, Filter } from "../../interfaces/filter.interface";
import { trigger, transition, style, animate } from '@angular/animations';
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({ transform: 'translateY(100%)', opacity: 0 }),
                    animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
                ])
            ]
        ),
        trigger(
            'enterAnimationValue', [
                transition(':enter', [
                    style({ transform: 'translateX(100%)', opacity: 0 }),
                    animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
                ]),
                transition(':leave', [
                    style({ transform: 'translateX(0)', opacity: 1 }),
                    animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
                ])
            ]
        )
    ],
})

export class TableComponent {
    
    @Input() filters: Filter[];
    @Input() columns: Column[];
    @Input() actions: Action[];
    @Input() data: any[];
    @Input() meta;
    @Output() pagination = new EventEmitter<number>();
    @Output() actionOuput = new EventEmitter<any>();

    page: number;
    first_page: boolean = true;
    last_page: boolean = false;
    links: any;

    filter: Filter = null;
    showFilters: boolean = false;
    showFiltersValue: boolean = false;
    showColumns: boolean = false;
    filterText: string = '';
    
    objectKeys = Object.keys;

    currentFilters: CurrentFilter[] = [];

    constructor(
        private router: Router,
        private translateService: TranslateService
    ){
        
    }

    ngOnChanges() {
        this.page = this.meta.current_page;
        this.first_page = this.meta.current_page == 1 ? true : false;
        this.last_page = this.meta.last_page === this.meta.current_page ? true : false;
        this.links = this.meta.links;
        this.showFilters = false;
        this.showColumns = false;
    }

    changeVisibilty(column: Column) {
        const total = this.columns.filter(column => true === column.visible);
        
        if(1 !== total.length)
            column.visible = !column.visible;
        else
            if(false === column.visible)
                column.visible = !column.visible;

        this.showFilters = false;
        this.showColumns = false;
    }

    getVariable(data, name): any {
        let value = null;
        
        if(name.includes('.')) {
            const arr = name.split('.');
            value =  data[arr[0]][arr[1]];
        } else {
            value = data[name];
        }

        if('boolean' === typeof value) {
            return true === value ? 'Sí': 'No';
        }
        
        return value;
    }

    showPerPage(number): boolean {
        if (number >= this.page && number != 0 && number != (this.links.length - 1) && number < (this.page + 2))
            return true;
        return false;
    }

    nextPage(page: any) {
        if (page == '+')
            page = ++this.page;

        if (page == '-')
            page = --this.page;

        this.pagination.emit(page);
    }

    generateUrl(action: string, data) {
        const regex = /{([^}]*)}/g;
        action.match(regex).forEach((match) => {
            action = action.replace(match, data[match.substring(1, match.length-1)]);
        });
        
        this.router.navigate([action], { state: { data: data } });
        return action;
    }

    generateAction(actionS: string, id) {
        const data = {action: actionS, id: id};
        this.actionOuput.emit(data);
    }

    filterValue(filter: Filter) {
        this.showFilters = false;
        this.showFiltersValue = true;
        this.filter = filter;
    }

    selectFilterValue(option) {
        this.showFilters = false;
        this.showFiltersValue = false;

        const newFilter = {
            value: option,
            name: this.filter.name,
            nameText: this.translateService.instant(this.filter.nameTranslate),
            valueText: this.filter.type !== 'text' ?  this.filter.options[option] : option
        };

        if(!this.currentFilters.find(elem => elem.name === newFilter.name)) {
            this.currentFilters.push(newFilter);
        }

        this.filterText = '';
        
        this.sendFilters();
    }

    deleteCurrentFilter(position: number) {
        this.currentFilters.splice(position, 1);
        this.sendFilters();
    }

    sendFilters() {
        this.actionOuput.emit({action: 'filterChange', id: this.currentFilters});
    }
}

