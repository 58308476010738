import { Injectable } from "@angular/core";
import { Category, Condition } from "./interfaces/category.interface";

@Injectable({
	providedIn: 'root'
})
export class Categories {

    public categories: Category[] = [
        {
            id: 1,
            conditions: [
                {
                    id: 1,
                    text: 'Para que la regla sea disparada, esta condición debe ser',
                    data: 'simpleInequalityTypes',
                    textResult: 'Para que se active esta Regla, el nivel de la batería debe ser %type% %value% %.'
                },
                {
                    id: 2,
                    text: 'Para que la regla sea disparada, se debe de haber perdido la visibilidad del Beacon durante más de',
                    data: 'timeTypes',
                    textResult: 'Para que se active esta Regla, se debe de haber perdido la visibilidad del Beacon durante %value% %type%'
                }
            ]
        },
        {
            id: 2,
            conditions: [
                {
                    id: 1,
                    text: 'Para que la regla sea disparada, esta condición debe ser',
                    data: 'inequalityTypes',
                    textResult: 'Para que esta regla sea disparada, la temperatura debe ser %type% al %value% %.'
                },
                {
                    id: 2,
                    text: 'Para que la regla sea disparada, esta condición debe ser',
                    data: 'inequalityTypes',
                    textResult: 'Para que esta regla sea disparada, la humedad debe ser %type% al %value% %.'
                },
                {
                    id: 3,
                    text: 'Para que la regla sea disparada, el siguiente botón debe ser presionado',
                    textResult: 'Para que la regla sea disparada, el botón Beacon (por defecto) debe ser presionado.'

                }
            ]
        },
        {
            id: 3,
            conditions: [
                {
                    id: 1,
                    text: 'Para que la regla sea disparada, se debe salir de esta ubicación',
                    textResult: 'Para que la regla sea disparada, se debe salir de esta ubicación.'
                },
                {
                    id: 2,
                    text: 'Para que la regla sea disparada, se debe entrar de esta ubicación',
                    textResult: 'Para que la regla sea disparada, se debe entrar de esta ubicación',
                },
                {
                    id: 3,
                    text: 'Para que la regla sea disparada, el Beacon debe haber permanecido en la Ubicación durante más de',
                    data: 'timeTypes',
                    textResult: 'Para que la regla sea disparada, el Beacon debe haber permanecido en la Ubicación durante %value% de %type%.'

                }
            ]
        },
        {
            id: 4,
            conditions: [
                {
                    id: 1,
                    text: 'Para que la regla sea disparada, esta condición debe ser',
                    data: 'inequalityTypes',
                    textResult: 'Para que se active esta Regla, la OCUPACIÓN DE LA ZONA debe ser %type% a %value% personas.'
                }
            ]
        },
    ];

    public getCategory(category_id: number, condition_id: number): Condition {
        const category = this.categories.find(category => category.id == category_id)
        return category['conditions'].find(condition => condition.id == condition_id)
    }
}