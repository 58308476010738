import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    manageError(httpError: HttpErrorResponse): string
    {
        return httpError.error.errors[0].detail;
    }
}