<div class="fixed inset-0 overflow-y-auto" style="z-index: 99999 !important;">
	<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block
		sm:p-0">

		<div class="fixed inset-0 transition-opacity" (click)="closeModalF()">
			<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
		</div>

		<!-- This element is to trick the browser into centering the modal contents. -->
		<span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

		<div  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg md:w-11/12 md:max-w-screen-md lg:w-11/12 lg:max-w-screen-md xl:w-1/2 xl:max-w-screen-xl"

			role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<div class="bg-gray-50 px-4 pt-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<svg class="w-6 h-6 pb-2 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg" (click)="closeModalF()">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
					</path>
				</svg>
			</div>
			<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div class="sm:items-start">
					<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
							{{title}}
						</h3>
						<div class="mt-2">
							<ng-content select="[data]"></ng-content>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto" *ngFor="let option of options">
					<button type="button" (click)="buttonAction(option.optionReturn)"
						class="{{option.class}}">
						{{option.text}}
					</button>
				</span>
			</div>

		</div>
	</div>
</div>