import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalInfoComponent } from 'src/app/shared/components/modals/info/modal-info.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import { AssetService } from '../../management/asset/asset.service';
import { AutomationService } from '../../management/automation/automation.service';
import { PlaceService } from '../../management/place/place.service';
import { UserService } from '../../management/user/user.service';
import { ConditionModalComponent } from './condition/condition.component';
import { ConditionToSend } from './condition/interfaces/conditionSend.interface';
import { FloorModalComponent } from './floor/create/floor-crete.component';
import { FloorModalHwComponent } from './floor/hw/floor-hw.component';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})

export class MenuComponent {

    public isOpenMobile = false;
    public showSmallNavBar = false;

    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
    ) { }

    changeValue(value?) {
        this.isOpenMobile = value ? value : !this.isOpenMobile;
    }

    public smallNavBar() {
        this.showSmallNavBar = !this.showSmallNavBar;
    }

    showModal() {
        this.modalService.init(ModalInfoComponent, this.translateService.instant('modal'), { closeModal: this.closeModal.bind(this) });
    }

    deleteUserModal(id, userService: UserService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.users.list.delete_modal'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); userService.deleteUserState(id) } });
    }

    deletePlaceModal(id, placeService: PlaceService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.places.list.delete_modal'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); placeService.deletePlaceState(id) } });
    }

    deletePlaceBuildingModal(id, placeService: PlaceService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.places.buildings.delete_modal'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); placeService.deletePlaceBuildingState(id) } });
    }

    deletePlaceBuildingFloorModal(id, placeService: PlaceService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.places.buildings.floors.delete_modal'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); placeService.deletePlaceBuildingFloorState(id) } });
    }

    createPlaceBuildingFloor(place_id, building_id) {

        let inputs = this.translateService.instant('managements.places.buildings.floors.createModal');

        inputs.place_id = place_id;
        inputs.building_id = building_id;
        
        this.modalService.init(
            FloorModalComponent, 
            inputs, 
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); } });
    }

    deleteAssetModal(id, assetService: AssetService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.assets.list.delete_modal'),
            { closeModal: this.closeModal.bind(this), buttonAction: ($action) => { this.closeModal(); assetService.deleteAssetState(id) } });
    }

    selectHwToBelongTo(type: string) {
        let inputs = this.translateService.instant('managements.places.buildings.floors.selectDevice');

        inputs.type = type;
        
        this.modalService.init(FloorModalHwComponent, inputs, { closeModal: this.closeModal.bind(this) });
    }

    deleteAutomationModal(id: number, type: string, automationService: AutomationService) {
        this.modalService.init(
            ModalInfoComponent,
            this.translateService.instant('managements.automation.list.delete_modal_'+type),
            { closeModal: this.closeModal.bind(this), buttonAction: () => { this.closeModal(); automationService.deleteAutomationState(id, type) } });
    }

    createContition(automationService: AutomationService) {
        let inputs = this.translateService.instant('managements.places.buildings.floors.selectDevice');
        this.modalService.init(
            ConditionModalComponent,
            inputs,
            { closeModal: this.closeModal.bind(this), buttonAction: (conditionToSend: ConditionToSend) => { this.closeModal(); this.saveCondition(conditionToSend, automationService) } }
        );
    }

    saveCondition(conditionToSend: ConditionToSend, automationService: AutomationService) {
        automationService.sendContitionToSend(conditionToSend);
    }

    closeModal() {
        this.modalService.destroy();
    }
}