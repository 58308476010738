import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../auth.service";
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorService } from "src/app/shared/services/error.service";

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {
    
    forgotForm: FormGroup;
    error: number;
    success: boolean = false;
    errorMessage: string;
    disabledButton: boolean = true;
	private debounce: number = 300;

    private subscription = new Subscription();
    
    constructor(
		private builder: FormBuilder,
		private authService: AuthService,
        private errorService: ErrorService
	) { }
    
    ngOnInit(): void {
        this.forgotForm = this.builder.group({
			email: ['', [Validators.required, Validators.email]],
		});

        this.forgotForm.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(
				() => {
                    this.disabledButton = true;
					if(this.forgotForm.valid) {
						this.disabledButton = false;
                    }
            	}
            );
    }

    get f() { return this.forgotForm.controls; }

    onSubmit(){
        this.subscription.add(
			this.authService.forgotPassword(this.forgotForm.controls['email'].value).subscribe(
				() => {
                    this.forgotForm.reset();
                    this.error = null;
                    this.disabledButton = true;
                    this.success = true;
                },
                (error) => this.errorMessage = this.errorService.manageError(error)
            )
        );
    }


    ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
