<app-modal-info-options [title]="'Crear condición'" [options]="options" (closeModal)="closeModalF()" (actionOuput)="actionEmit($event)">
    <div data class="w-full p-2">
        <div class="flex flex-col">
            <div *ngIf="step === 1" [@moveBetweenSteps]>
                <h2 class="mb-2">Seleccionar Categoría</h2>
        
                <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                    <div class="w-full md:w-1/4">
                        <div class="flex items-center pl-4 rounded">
                            <input id="bordered-radio-1" type="radio" value=1 [(ngModel)]="category"
                            class="w-4 h-4 text-bizdata-600 border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                            <label for="bordered-radio-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                Señales de beacons
                            </label>
                        </div>
                    </div>
                    <div class="w-full md:w-1/4">
                        <div class="flex items-center pl-4 rounded">
                            <input id="bordered-radio-2" type="radio" value=2 [(ngModel)]="category"
                            class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                            <label for="bordered-radio-2" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                Telemetría
                            </label>
                        </div>
                    </div>
                    <div class="w-full md:w-1/4">
                        <div class="flex items-center pl-4 rounded">
                            <input id="bordered-radio-3" type="radio" value=3 [(ngModel)]="category"
                            class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                            <label for="bordered-radio-3" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                Ubicaciones
                            </label>
                        </div>
                    </div>
                    <div class="w-full md:w-1/4">
                        <div class="flex items-center pl-4 rounded">
                            <input id="bordered-radio-4" type="radio" value=4 [(ngModel)]="category" 
                            class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                            <label for="bordered-radio-4" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                Ocupación
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="step ===2" [@moveBetweenSteps]>
                <h2 class="mb-2">Seleccionar Condición</h2>
                <span [ngSwitch]="category">
                    <ng-container *ngSwitchCase=1>
                        <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-1" type="radio" value=1 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600 border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Nivel de batería
                                    </label>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-2" type="radio" value=2 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-2" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Visibilidad del beacon
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="condition">
                            <h2 class="mb-2">Configurar Condición</h2>
                            <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                                <ng-container *ngIf="condition == 1">
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                                {{text}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <select class="input-form" [(ngModel)]="dataCondition.type">
                                                <option value="{{type.value}}" *ngFor="let type of data">
                                                    {{type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <input type="number" class="input-form" [(ngModel)]="dataCondition.value">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="condition == 2">
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                               {{text}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <input type="number" class="input-form" [(ngModel)]="dataCondition.value">
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <select class="input-form" [(ngModel)]="dataCondition.type">
                                                <option value="{{type.value}}" *ngFor="let type of data">
                                                    {{type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase=2>
                        <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-1" type="radio" value=1 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600 border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                       Temperatura
                                    </label>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-2" type="radio" value=2 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-2" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Humedad
                                    </label>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-3" type="radio" value=3 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-3" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Clic botón beacon
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="condition">
                            <h2 class="mb-2">Configurar Condición</h2>
                            <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                                <ng-container *ngIf="condition != 3">
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                                {{text}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <select class="input-form" [(ngModel)]="dataCondition.type">
                                                <option value="{{type.value}}" *ngFor="let type of data">
                                                    {{type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <input type="number" class="input-form" [(ngModel)]="dataCondition.value">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="condition == 3">
                                    <div class="w-full">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                               {{text}}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase=3>
                        <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-1" type="radio" value=1 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600 border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                       Salir de la ubicación
                                    </label>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-2" type="radio" value=2 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-2" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Entra en la ubicación
                                    </label>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-3" type="radio" value=3 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600  border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-3" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Tiempo de permanencia en ubicación
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="condition">
                            <h2 class="mb-2">Configurar Condición</h2>
                            <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                                <ng-container *ngIf="condition == 3">
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                                {{text}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <input type="number" class="input-form" [(ngModel)]="dataCondition.value">
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <select class="input-form" [(ngModel)]="dataCondition.type">
                                                <option value="{{type.value}}" *ngFor="let type of data">
                                                    {{type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="condition != 3">
                                    <input type="hidden" value="" class="input-form" [(ngModel)]="dataCondition.value">
                                    <input type="hidden" value="" class="input-form" [(ngModel)]="dataCondition.type">
                                    <div class="w-full">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                               {{text}}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase=4>
                        <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                            <div class="w-full md:w-1/2">
                                <div class="flex items-center pl-4 rounded">
                                    <input id="bordered-condition-1" type="radio" value=1 [(ngModel)]="condition" (change)="changeCondition()"
                                    class="w-4 h-4 text-bizdata-600 border-bizdata-300 focus:ring-bizdata-500 focus:ring-2">
                                    <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                        Ocupación Zona
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="condition">
                            <h2 class="mb-2">Configurar Condición</h2>
                            <div class="flex flex-col md:flex-row md:space-x-5 space-x-0">
                                <ng-container *ngIf="condition == 1">
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <label for="bordered-condition-1" class="py-4 ml-2 w-full text-sm font-medium text-bizdata-900">
                                                {{text}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <select class="input-form" [(ngModel)]="dataCondition.type">
                                                <option value="{{type.value}}" *ngFor="let type of data">
                                                    {{type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/3">
                                        <div class="flex items-center pl-4 rounded">
                                            <input type="number" class="input-form" [(ngModel)]="dataCondition.value">
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </span>
            </div>
            <div *ngIf="step ===3" [@moveBetweenSteps]>
                <h2 class="mb-2">Resumen</h2>
                <p class="text-sm">
                    La siguiente condición debe cumplirse para activar esta política.
                    Puede utilizar el botón ATRÁS para realizar cambios.
                </p>
                <span class="text-sm text-bizdata-500">
                    {{ generateCondition() }}
                </span>
            </div>
        </div>
    </div>
</app-modal-info-options>