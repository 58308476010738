<app-modal-data [title]="title" [text]="text" (closeModal)="closeModalF()">
    <div data class="w-full p-2" *ngIf="floorForm">
        <div class="flex flex-wrap" [formGroup]="floorForm">
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="name" name="{{ 'managements.places.buildings.floors.createModal.name' | translate }}">
                </app-label-form>
                <input formControlName="name" class="input-form" id="name"
                    type="text" [ngClass]="{ 'border border-red-500': submitted && f.name.errors }"
                    placeholder="{{ 'managements.places.buildings.floors.createModal.name' | translate }}">
                <ng-container *ngIf="submitted && f.name.errors">
                    <app-error-form error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
            <div class="w-full sm:w-1/2 p-2">
                <app-label-form for="image" name="{{ 'managements.places.buildings.floors.createModal.image' | translate }}"></app-label-form>
                <div class="mt-1">
                    <input class="border input-form" id="image" accept="image/*" (change)="onFileChange($event) "type="file" />
                </div>
                <p class="text-bizdata text-xs italic pt-2">
                    JPG, PNG, BPM y TIFF son formatos soportados. El archivo no debería ser mayor de 800kB
                </p>
                <ng-container *ngIf="submitted && f.photo.errors">
                    <app-error-form error="{{'errors.required' | translate}}">
                    </app-error-form>
                </ng-container>
            </div>
        </div>
        <div class="p-2 flex justify-end">
            <button (click)="createFloor()" type="submit" class="button-ok-form">
                {{text_button_create}}
            </button>
        </div>
    </div>
</app-modal-data>