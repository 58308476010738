<div class="bg-white shadow-sm py-4 w-full">
    <div
        class="space-y-3 sm:flex sm:items-center sm:space-x-4 sm:space-y-0 justify-evenly">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ menuName | translate|uppercase }}
        </h3>
        <ng-container *ngFor="let link of links; let i=index">
            <div routerLinkActive="active" class="inline-flex cursor-pointer text-white p-2 space-x-1 rounded-md items-center link" (click)="goToManagement(link.path)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span>{{link.name}}</span>
            </div>
        </ng-container>
    </div>
</div>
