
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'managements', pathMatch: 'full' },
            {
                path: 'managements',
                loadChildren: () => import('./management/management.module').then(x => x.ManagementModule),
                canActivate: [AuthGuard] 
            },
            {
                path: 'tracking',
                loadChildren: () => import('./tracking/tracking.module').then(x => x.TrackingModule),
                canActivate: [AuthGuard] 
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {

}