import { Component, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { PlaceService } from "src/app/dashboard/management/place/place.service";
import { SnackbarService } from "src/app/shared/components/snackbar/snackbar.service";
import { UtilsService } from "src/app/shared/services/util.service";

@Component({
    selector: 'app-floor-modal',
    templateUrl: './floor-create.component.html'
})

export class FloorModalComponent implements OnInit {

    @Input() place_id;
    @Input() building_id;
    @Input() title: string;
    @Input() text: string;
    @Input() text_button_create: string;
    @Output() closeModal;

    floorForm: FormGroup;
    submitted = false;
    subscription = new Subscription;

    constructor(
        private builder: FormBuilder,
        private placeService: PlaceService,
        private utilService: UtilsService,
        private snackbarService: SnackbarService
    ) {
        
    }
    
    ngOnInit(): void {
        this.floorForm = this.builder.group({
            name: [null, [Validators.required, Validators.maxLength(45)]],
            photo: [null, Validators.required],
            photo_extension: [null, null]
        });
    }

    get f() { return this.floorForm.controls; }

    closeModalF() {
        this.closeModal();
    }

    onFileChange(event) {
		const reader = new FileReader();
		 
		if(event.target.files && event.target.files.length) {
		  	const [file] = event.target.files;
			
		  	reader.readAsDataURL(file);
		 
		  	reader.onload = () => {
                this.floorForm.patchValue({
					photo: reader.result,
					photo_extension: file.type
				});
			};
		}
	}

    createFloor() {
        this.submitted = true;

        if (this.floorForm.invalid) {
            return;
        }

        let floor = this.utilService.clear(this.floorForm.value);
        this.subscription.add(this.placeService.createBuildingFloor(this.place_id, this.building_id, floor).subscribe(
            () => {
                this.snackbarService.show('Planta creada correctamente.', 'success');
                window.location.reload();
            },
            () => {
                this.snackbarService.show('Algo ha pasado ....', 'danger');
            }
        ));
    }
}

