import { Component, Input } from "@angular/core";
import { Breadcumb } from "../../interfaces/breadcumb.interface";


@Component({
	selector: 'app-breadcumb',
	templateUrl: './breadcumb.component.html'
})

export class BreadcumbComponent {
    
    @Input() breadcumbs: Breadcumb[];
}