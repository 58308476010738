import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Link } from "../../interfaces/link.interface";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent {
    
    @Input() name: string;
    @Input() links: Link[];
    @Input() menuName: string;

    constructor(
        private router: Router
	) 
    {}

    goToManagement(path: string)
    {
        this.router.navigate([`/${this.name}/${path}`]);
    }
}